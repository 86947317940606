/**
 * useLocaleHeadConfig composable
 * -----------------------
 * a shared config to set the <head> title and description
 *
 * title
 * -----
 * it will be the t('default.head.title') concatenated with either:
 *    - the title given in parameter (optional)
 *    - OR automatically the title in the translation file for the key 'ROUTE_NAME.head.title'
 *
 * description
 * -----------
 * it will be either
 *    - the description given in parameter (optional)
 *    - OR automatically the description in the translation file for the key 'ROUTE_NAME.head.description'
 */

export const useLocaleHeadConfig = (
  title?: string | null,
  description?: string | null
) => {
  const { te, t } = useI18n()

  // remove the local code at the end of the route name
  const routeName = useRouteName()

  const _title =
    title || (te(`${routeName}.head.title`) && t(`${routeName}.head.title`))
  const _description =
    description ||
    (te(`${routeName}.head.description`) && t(`${routeName}.head.description`))

  const titleParts = [t('default.head.title')]
  _title && titleParts.push(_title)

  const { locale } = useI18n()
  useHead({
    htmlAttrs: {
      lang: locale
    },
    title: titleParts.join(' - '),
    meta: [
      { name: 'description', content: _description || t('default.head.title') }
    ]
  })
}
